'use client'

import { MouseEvent } from 'react'
import { Button } from '@vinted/web-ui'
import { useRouter } from 'next/router'

import useRefUrl from 'hooks/useRefUrl'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'

import { SIGNUP_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { clickEvent } from 'libs/common/event-tracker/events'
import { toUrlQuery } from 'libs/utils/url'
import useAuthModal from 'libs/common/auth-modal/useAuthModal'

const HeaderLoginButton = () => {
  const refUrl = useRefUrl()
  const translate = useTranslate()
  const { track } = useTracking()
  const { openAuthModal } = useAuthModal()
  const router = useRouter()
  const isAuthPageOpen = router.pathname === SIGNUP_URL

  function handleClick(event: MouseEvent) {
    track(clickEvent({ target: ClickableElement.Login }))

    if (isAuthPageOpen) return

    event.preventDefault()
    openAuthModal()
  }

  return (
    <Button
      text={translate('header.actions.login')}
      size={Button.Size.Small}
      url={`${SIGNUP_URL}?${toUrlQuery({ ref_url: refUrl })}`}
      onClick={handleClick}
      testId="header--login-button"
    />
  )
}

export default HeaderLoginButton
